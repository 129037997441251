// React
import React,
	   {Component} from "react";

// App
import LOGO from "./assets/G-UL-Logo-Big.png";


class Header extends Component
{
	render()
	{
		return (
			<nav className="navbar
							navbar-light
							bg-light">
				<div className="col-md-12"
					 style={{display: "inline-grid"}}>
					<a href="https://hello.g-ul.me"
					   id="main-logo1">
						<img alt={"logo"}
							 height="48"
							 width="48"
							 src={LOGO} />
						<p className="g-header">
							G's Metronome
						</p>
					</a>



				</div>
			</nav>
		);
	}
}

export default Header;
